export const indexPageSlug = 'index'

export const site = {
  name: 'Sharp.com',
  title: 'Sharp HealthCare',
  description:
    "Sharp HealthCare is San Diego's health care leader with hospitals in San Diego, affiliated medical groups, urgent care centers and a health plan. Sharp provides medical services in virtually all fields of medicine, including primary care, heart care, cancer treatment, orthopedics and women’s health. Looking for San Diego doctors? Seeking nursing jobs in San Diego? Visit Sharp.com or call 1-800-82-SHARP.",
  hostname: 'www.sharp.com',
  url: 'https://www.sharp.com',
}

export const twitter = {
  site: '@sharphealthcare',
}

// The first key is the content type for the page and the second is the path for these content types
export const contentTypeBasePathMap: Record<string, string> = {
  campaignPageEnvision: '/envision/',
  campaignNewsEnvision: '/envision/news/',
  campaignFundraisingInitiative: '/envision/initiatives/',
  giveNews: '/give/news/',
  healthNewsTopic: '/health-news/topics/',
  mediaBoard: '/digital-signage/media/',
  medicalService: '/services/',
  newsArticle: '/health-news/',
  page: '/',
  pageGive: '/give/',
  person: '/health-news/authors/',
  place: '/locations/',
  schedule: '/digital-signage/schedules/',
  provider: '/doctors/',
}

export const blockQueryParamMap: Record<string, string> = {
  campaignCardImageLongBlock: 'campaign-card-image-long-page',
  healthNewsStoriesBlock: 'health-news-stories-page',
  providerNewsBlock: 'provider-news-page',
}

export const modalImportMap = {
  'Health news sign up': import('@components/newsletter-signup'),
  'Foundation news sign up': import('@components/foundation-newsletter-signup'),
}

export const searchInputDebounceMs = 300
export const searchMinimumQueryLength = 3

export const stateOptions = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]
