import type { ComponentPropsWithoutRef, ReactElement } from 'react'
import clsx from 'clsx'
import FieldRequiredMark from '@components/field-required-mark'

export interface FieldLabelProps extends ComponentPropsWithoutRef<'span'> {
  label: string | ReactElement
  hideLabel?: boolean
  isError?: boolean
  disabled?: boolean
  required?: boolean
}
const FieldLabel = ({
  label,
  hideLabel = false,
  isError = false,
  className,
  ...props
}: FieldLabelProps) => (
  <span
    className={clsx(
      'font-medium text-base',
      hideLabel && 'sr-only',
      props.disabled && 'text-black/40',
      isError && !props.disabled && 'text-danger',
      !isError && !props.disabled && 'text-gray-700',
      className
    )}
    {...props}>
    {label}
    {props.required && (
      <FieldRequiredMark isError={isError} className={className} disabled={props.disabled} />
    )}
  </span>
)

export default FieldLabel
