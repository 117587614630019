import { type Ref, forwardRef, type ComponentPropsWithRef, type ReactElement } from 'react'
import clsx from 'clsx'
import FieldHelp from '@components/field-help'
import FieldRequiredMark from '@components/field-required-mark'

export interface InputProps
  extends ComponentPropsWithRef<'input'>,
    Omit<ComponentPropsWithRef<'input'>, 'type'> {
  label: string | ReactElement
  helpText?: string | ReactElement
  isError?: boolean
  validationText?: string | ReactElement
  ref?: Ref<HTMLInputElement>
}

const Checkbox = (
  { label, helpText, isError = false, validationText, className, ...props }: InputProps,
  ref: Ref<HTMLInputElement>
) => (
  <div className="inline-flex flex-col">
    <label
      className={clsx(
        'inline-flex items-start',
        props.disabled && 'text-black/40 cursor-not-allowed',
        !props.disabled && 'text-gray-700 cursor-pointer'
      )}>
      <input
        type="checkbox"
        ref={ref}
        {...props}
        className={clsx(
          'appearance-none',
          'h-5 w-5 transition mt-1',
          'border-2 rounded',
          'focus:ring-0 focus:ring-offset-0',
          'focus:outline-0 focus:outline-offset-0',
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
          'focus-visible:outline-offset-transparent focus-visible:outline-primary',
          'border-gray-200 accent-primary text-primary',
          props.disabled && 'cursor-not-allowed',
          !props.disabled && 'cursor-pointer hover:border-gray-700',
          className
        )}
      />
      <div className="ml-2.5">
        {label}
        {props.required && <FieldRequiredMark disabled={props.disabled} />}
      </div>
    </label>
    {helpText && <FieldHelp disabled={props.disabled} helpText={helpText} className="ml-[31px]" />}
    {isError && !props.disabled && validationText && (
      <div className={clsx('ml-[31px] text-danger text-sm')}>{validationText}</div>
    )}
  </div>
)

export default forwardRef(Checkbox)
