import type { ComponentPropsWithRef, ReactElement } from 'react'
import clsx from 'clsx'
import FieldHelp from '@components/field-help'
import FieldRequiredMark from '@components/field-required-mark'

interface FormGroupProps extends ComponentPropsWithRef<'fieldset'> {
  label: string | ReactElement
  helpText?: string | ReactElement
  required?: boolean
  isError?: boolean
  validationText?: string | ReactElement
  legendClassName?: string
  layout?: 'row' | 'column'
}

// TODO: 12px when helptext and row

const FieldSet = ({
  label,
  isError = false,
  required = false,
  validationText,
  helpText,
  legendClassName,
  className,
  layout = 'column',
  children,
  ...props
}: FormGroupProps) => (
  <fieldset {...props} className={clsx(className != '' && className)}>
    <legend
      className={clsx(
        legendClassName ? legendClassName : 'font-semibold',
        props.disabled && 'text-black/40',
        isError && !props.disabled && 'text-danger',
        !isError && !props.disabled && 'text-gray-700'
      )}>
      {label}
      {required && <FieldRequiredMark disabled={props.disabled} />}
    </legend>
    {helpText && <FieldHelp disabled={props.disabled} helpText={helpText} className="mt-2" />}
    <div
      className={clsx(
        'flex',
        layout === 'column' && 'flex-col gap-y-3 mt-3',
        layout === 'row' && 'flex-row gap-x-10',
        layout === 'row' && !helpText && 'mt-2',
        layout === 'row' && helpText && 'mt-3'
      )}>
      {children}
    </div>
    {isError && !props.disabled && validationText && (
      <div className="text-danger text-sm mt-2">{validationText}</div>
    )}
  </fieldset>
)

export default FieldSet
