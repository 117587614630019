import type { ReactElement, ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

export interface FieldHelpProps extends ComponentPropsWithoutRef<'div'> {
  disabled?: boolean
  helpText?: string | ReactElement
}
const FieldHelp = ({ disabled, helpText, className, ...props }: FieldHelpProps) => (
  <div
    aria-disabled={disabled ? 'true' : undefined}
    className={clsx('text-sm', disabled ? 'text-black/40' : 'text-gray-700', className)}
    {...props}>
    {helpText}
  </div>
)

export default FieldHelp
