'use client'
import { createContext, useMemo } from 'react'
import { useSearchParams } from 'next/navigation'
import { isEmpty, omitBy } from 'lodash'
import type { SPContext } from '@hooks/use-analytics'
import { useInstantSearch } from 'react-instantsearch'

export type TPageContext = Extract<SPContext, { name: 'page' }>
export type TPlaceContext = Extract<SPContext, { name: 'place' }>
export type TProviderContext = Extract<SPContext, { name: 'provider' }>
export type TContentContext = Extract<SPContext, { name: 'content' }>
export type TSearchContext = Extract<SPContext, { name: 'search' }>

// Contexts (if these are still needed outside the hook)
export const PageContext = createContext<TPageContext | null>(null)
export const ProviderContext = createContext<TProviderContext | null>(null)
export const PlaceContext = createContext<TPlaceContext | null>(null)
export const BlockContext = createContext<TContentContext | null>(null)
export const ItemContext = createContext<TContentContext | null>(null)

// Custom hook
export function useBuildSearchContext(): SPContext {
  const searchParams = useSearchParams()
  const { results, scopedResults } = useInstantSearch()
  const queryValue = results._state?.query ?? ''
  const locationFieldValue = searchParams?.get('l')?.trim() ?? null

  // special case for multi-index search
  let multiIndexLabel = null
  let multiIndexResultCount = null
  if (scopedResults?.length > 1) {
    multiIndexLabel = 'all'
    multiIndexResultCount = scopedResults
      .slice(1) // remove first index of the array (crawler_sharp_com) because we don't want to count these hits
      .reduce((total, index) => total + index.results.nbHits, 0) // sum up the hits of the remaining indexes
  }

  return useMemo(
    () => ({
      name: 'search',
      data: {
        search_query: queryValue,
        search_location: locationFieldValue,
        search_index: multiIndexLabel ?? results.index,
        search_result_count: multiIndexResultCount ?? results.nbHits ?? -1,
        search_facets: JSON.stringify({
          ...omitBy(results._state?.disjunctiveFacetsRefinements ?? {}, isEmpty),
          ...omitBy(results._state?.facetsRefinements ?? {}, isEmpty),
        }),
      },
    }),
    [
      locationFieldValue,
      queryValue,
      results.index,
      results.nbHits,
      results._state?.disjunctiveFacetsRefinements,
      results._state?.facetsRefinements,
      multiIndexLabel,
      multiIndexResultCount,
    ]
  )
}

const methods = {
  useBuildSearchContext,
}

export default methods
