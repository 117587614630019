import { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

export interface FieldRequiredMarkProps extends ComponentPropsWithoutRef<'span'> {
  isError?: boolean
  disabled?: boolean
}
// role img prevents screen reader from reading "star" and instead the aria label
const FieldRequiredMark = ({ className, isError, ...props }: FieldRequiredMarkProps) => (
  <span
    role="img"
    aria-label="required"
    className={clsx(
      'font-medium text-base pl-1',
      props.disabled ? 'text-black/40' : 'text-danger',
      className
    )}>
    *
  </span>
)

export default FieldRequiredMark
