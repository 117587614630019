'use client'

import type { SearchNamespace } from '@app/[locale]/(main)/search/_components/search-session'
import type { TypeMedicalGroup } from '@lib/location-types'
import aa from 'search-insights'

const nearbyUCSearch = (medicalGroups: TypeMedicalGroup[]): string => {
  // Setting up the search filter
  const placeTypeFacetString = 'Urgent care and walk-in clinics > Urgent care centers'

  const filteredMedicalGroup = medicalGroups.find(
    (group: any) => group.name === 'Marque Medical' || group.name === 'Sharp Rees-Stealy'
  )

  const medGroupFacetSearch = filteredMedicalGroup?.name
    ? `medical_groups_facet:'${filteredMedicalGroup.name}'`
    : null

  const placeTypeFacetSearch = `place_type_facet:'${placeTypeFacetString}'`

  return medGroupFacetSearch
    ? `${placeTypeFacetSearch} AND ${medGroupFacetSearch}`
    : `${placeTypeFacetSearch}`
}

/**
 * Tracks a conversion event in Algolia using the `convertedObjectIDsAfterSearch` method.
 *
 * This function sends a conversion event to Algolia's insights client, indicating that an object
 * was converted after a search. It retrieves the search query ID and search index name from
 * session storage.
 *
 * Assumptions:
 * - `aa` (Algolia Analytics) is available in the global scope. See <ClientWrapper>
 * - The search query ID and search index name are stored in session storage. See <SearchSession>
 * - The session is tied to a browser window. If a user opens a link in a new window, the session
 *   will no longer be available, and valid Algolia events cannot be sent without a queryId and indexName.
 *
 * @param {SearchNamespace} searchNamespace - The namespace used for session storage keys.
 * @param {string} objectId - The ID of the object that was converted.
 * @param {string} eventName - The name of the event to be tracked.
 *
 * @returns {void}
 */
export const trackAlgoliaConversionEvent = (
  searchNamespace: SearchNamespace,
  objectId: string,
  eventName: string
) => {
  // Get data from session storage
  const sessionQueryId = sessionStorage.getItem(`${searchNamespace}_searchQueryId`)
  const sessionIndexName = sessionStorage.getItem(`${searchNamespace}_searchIndexName`)

  if (sessionIndexName && sessionQueryId && objectId) {
    aa('convertedObjectIDsAfterSearch', {
      index: sessionIndexName,
      eventName,
      queryID: sessionQueryId,
      objectIDs: [objectId],
    })
  }
}

export const algoliaUtilites = {
  nearbyUCSearch,
  trackAlgoliaConversionEvent,
}

export default algoliaUtilites
