'use client'

import { useEffect } from 'react'

export type SearchNamespace = 'providers' | 'places' | 'relatedPages'

export const searchNamespaceLabelMap: Record<SearchNamespace, string> = {
  providers: 'Providers',
  places: 'Places',
  relatedPages: 'Related Pages',
}

type SearchSessionProps = {
  /** The initial index name to be stored in session storage. */
  indexName: string
  /** The initial query ID to be stored in session storage. @default undefined  */
  queryId?: string
  /** The namespace to be used for session storage keys. */
  namespace: SearchNamespace
}

/**
 * SearchSession component initializes and manages session storage for search parameters.
 *
 * This component sets up session storage for `searchIndexName` and `searchQueryId` using the provided
 * `indexName` and `queryId` props. It ensures that these values are kept in sync with the session storage.
 *
 */
export default function SearchSession({ indexName, queryId, namespace }: SearchSessionProps) {
  // Create namespaced keys
  const searchIndexNameKey = `${namespace}_searchIndexName`
  const searchQueryIdKey = `${namespace}_searchQueryId`

  // set session.searchIndexName
  useEffect(() => {
    sessionStorage.setItem(searchIndexNameKey, indexName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexName])

  // set session.searchQueryId
  useEffect(() => {
    if (queryId) {
      sessionStorage.setItem(searchQueryIdKey, queryId)
    } else {
      // take a look at this if we want it undefined or not present
      sessionStorage.removeItem(searchQueryIdKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId])

  return null
}
