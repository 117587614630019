import clsx from 'clsx'
import type { HTMLProps, Ref } from 'react'
import { forwardRef } from 'react'

const Backdrop = ({ className, ...props }: HTMLProps<HTMLDivElement>, ref: Ref<HTMLDivElement>) => (
  <div
    ref={ref}
    {...props}
    className={clsx(
      'transition bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm',
      className
    )}
  />
)

export default forwardRef(Backdrop)
